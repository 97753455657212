/**
 * SocialEmbedHint.jsx
 *
 * @file This file exports a component which will be displayed to the user,
 * if the user doesn't allow embedded content from social media.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiLink from '@mui/material/Link'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'

// internal imports
import {
	changePermissionSocial,
	selectCookieAllowedSocial,
	selectCookieSocial,
	selectCookieVisibility,
	setVisibility
} from '../../state'
import { Link } from '../Link'
import routes from '../../data/routes.json'

/**
 * Create a component which is rendered as a hint for social media embeds,
 * if the user doesn't allow embedded content.
 *
 * @param {Object} props – The component props.
 * @returns {Node} The styled component.
 */
const SocialEmbedHint = ({ href, service }) => {

	/** Get the cookie preference for allowed social media content from the store. */
	const allowedSocial = useSelector( selectCookieAllowedSocial )
	/** Get the cookie preference for social media content from the store. */
	const socialCookies = useSelector( selectCookieSocial )
	/** Get the `isVisible` preference from the redux store. */
	const visibility = useSelector( selectCookieVisibility )

	/** Define a state holding the state of the switch. */
	const [ checked, setChecked ] = useState( allowedSocial[ service ] )

	const dispatch = useDispatch()

	return (
        <Box
			data-wordcount={ href ? 61 : 57 }
			sx={{
				position: 'relative',
				zIndex: 1,
				[`&:after`]: {
					clear: 'both',
					content: '""',
					display: 'table',
				},
				[`&:before`]: {
					content: '""',
					float: 'left',
					height: 0,
					marginLeft: -1,
					paddingTop: 'calc( (9 / 16) * 100% )',
					width: 1,
				},
			}}>
			<Box sx={{ bgcolor: 'background.default', left: 0, position: 'absolute', top: 0 }}>
				<Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
					<Box sx={{ px: 2, py: 1 }}>
						<Typography gutterBottom variant="h6">Empfohlener Inhalt</Typography>
						<Typography paragraph variant="body2">An dieser Stelle finden Sie einen externen Inhalt von { service }. Dieser wird zur Zeit nicht angezeigt, da Ihre Cookie-Einstellungen die Einbindung von externen Inhalten nicht zulassen.</Typography>
						<Box sx={{ mb: 1.5 }}>
							{ !socialCookies ?
								<Button
									color="primary"
									onClick={ () => {
										if ( !visibility ) dispatch( setVisibility( true ) )
									} }
									size="small"
									variant="text">
									Cookie-Einstellungen öffnen
								</Button>
							:
								<FormControlLabel
									control={ (
										<Switch
											checked={ checked }
											onChange={ event => {
												const targetChecked = event.target.checked
												setChecked( targetChecked )
												setTimeout( () => {
													dispatch( changePermissionSocial( { social: service, value: targetChecked } ) )
												}, 1500 )
											} } />
									) }
									label={ !checked ? `${ service }-Inhalte erlauben?` : `${ service }-Inhalte erlaubt!` } />
							}
						</Box>
						<Typography color="textSecondary" variant="caption">Ich bin damit einverstanden, dass mir Inhalte von { service } angezeigt werden.</Typography>
						<Typography color="textSecondary" paragraph variant="caption">Personenbezogene Daten können an Drittplattformen übermittelt werden. Mehr dazu finden Sie in unserer <Link to={ routes[ 6 ].path }>{ routes[ 6 ].title }</Link>.</Typography>
						{ href && <Typography color="textSecondary" variant="caption">Link zum <MuiLink href={ href } rel="noopener noreferrer" target="_blank" underline="hover">{ `${ service }-Inhalt` }</MuiLink></Typography> }
					</Box>
				</Box>
			</Box>
		</Box>
    )
}

/**
 *
 */
SocialEmbedHint.propTypes = {
	href: PropTypes.string,
	service: PropTypes.oneOf( [
		'Facebook',
		'Instagram',
		'Slideshare',
		'Twitter',
		'YouTube'
	] ).isRequired
}

export default SocialEmbedHint
