/**
 * IndexSlideShow.jsx
 *
 * @file This component creates the slide show on the index "home" page.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CircularProgress from '@mui/material/CircularProgress'
import Fade from '@mui/material/Fade'
import { gql, useQuery } from '@apollo/client'
import IconButton from '@mui/material/IconButton'
import moment from 'moment'
import NProgress from 'nprogress'
import Radio from '@mui/material/Radio'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioGroup from '@mui/material/RadioGroup'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'

/** Query to get the news from the server. */
const GET_NEWS = gql`
	query GetNews( $newsAfter: Date ) {
    	allNews( expiresAfter: $newsAfter first: 15 status: PUBLISH ) {
			data {
				content
				id
				title
			}
			paginatorInfo {
				count
				hasMorePages
			}
		}
	}
`

/**
 * This component renders the slide show for the index "home" page.
 *
 * @returns {Node} The rendered slide show.
 */
const IndexSlideShow = () => {

	/** Define a state that holds the current index of the slide show item. */
	const [ index, slide ] = useState( 0 )
	/** Define a state that holds the news fetched from the server. */
	const [ news, storeNews ] = useState( {} )

	/** Fetch the latest news from the server. */
	const { error, loading } = useQuery( GET_NEWS, {
		displayName: 'GetNewsQuery',
		onCompleted: data => {
			if ( data && data.allNews ) {
				storeNews( data.allNews.data )

				if ( data.allNews.data.length > 1 )
					slideShowID = setInterval( () => {
						let i = index
						i++

						if ( i === data.allNews.data.length ) i = 0

						slide( i )
					}, 10000 )
			}
		},
		ssr: false,
		variables: { newsAfter: moment().format( 'YYYY-MM-DD' ) }
	} )

	let slideShowID = null

	useEffect( () => {
		return () => {
			if ( slideShowID ) clearInterval( slideShowID )
		}
	} )

	if ( loading ) {
		NProgress.start()

		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<CircularProgress
					size={ 50 }
					sx={{ color: 'secondary.main' }} />
			</Box>
		)
	}

	NProgress.done()

	if ( error )
		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<Typography>Es trat ein unerwarteter Fehler auf.</Typography>
			</Box>
		)

	return news.length > 0 ?
		(
			<>
				<Box sx={{ m: 'auto', maxHeight: 300, maxWidth: '100%', position: 'relative' }}>
					<IconButton
                        onClick={ () => {
							let i = index
							i--

							if ( i < 0 ) i = news.length - 1

							slide( i )
						} }
                        size="large"
						sx={{
							left: 0,
							mt: -22,
							p: 1,
							position: 'absolute',
							top: '50%',
							zIndex: (theme) => theme.zIndex.mobileStepper,
						}}>
						<ChevronLeftIcon />
					</IconButton>

					{ news.map( ( { content, id, title }, i ) => (
						<Fade in={ index === i } key={ id }>
							<Box sx={ index === i ? {
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								height: '100%',
								px: 5,
								py: 0,
								textAlign: 'center',
								width: '100%',
							} : {
								display: 'none'
							} }>
								<Typography gutterBottom sx={{ position: 'relative', width: '100%' }} variant="h6">{ title }</Typography>
								<Typography sx={{ position: 'relative', width: '100%' }} variant="body2">{ content }</Typography>
							</Box>
						</Fade>
					) ) }

					<IconButton
                        onClick={ () => {
							let i = index
							i++

							if ( i === news.length ) i = 0

							slide( i )
						} }
                        size="large"
						sx={{
							mt: -22,
							p: 1,
							position: 'absolute',
							right: 0,
							top: '50%',
							zIndex: (theme) => theme.zIndex.mobileStepper,
						}}>
						<ChevronRightIcon />
					</IconButton>
				</Box>
				<Box sx={{ alignContent: 'center', display: 'flex', width: '100%' }}>
					<RadioGroup
						name="dots"
						onChange={ e => { slide( parseInt( e.target.value ) ) } }
						sx={{ flexDirection: 'row', mx: 'auto', my: 0 }}
						value={ index.toString() }>
						{ news.map( ( { id }, i ) => (
							<Radio
								checkedIcon={ <RadioButtonCheckedIcon sx={{ fontSize: 18 }} /> }
								color="primary"
								icon={ <RadioButtonUncheckedIcon sx={{ fontSize: 18 }} /> }
								key={ id }
								value={ i.toString() } />
						) ) }
					</RadioGroup>
				</Box>
			</>
		)
	:
		(
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<Typography variant="body2">
					Zur Zeit gibt es keine Neuigkeiten.
				</Typography>
			</Box>
		)

}

export default IndexSlideShow
