/**
 * index.jsx
 *
 * @file Index page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import React, { useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'

// internal imports
import { IndexSlideShow as SlideShow } from '../components/IndexSlideShow'
import { LeavingSiteAlert } from '../components/alerts'
import { selectCookieAllowedSocial, selectCookieSocial } from '../state'
import { SocialEmbedHint } from '../components/SocialEmbedHint'

/**
 * This Component represents the index "home" page of this application.
 *
 * @returns {Node} The rendered page.
 */
const IndexPage = () => {

	/** Get the cookie preference for allowed social media content from the store. */
	const allowedSocial = useSelector( selectCookieAllowedSocial )
	/** Get the cookie preference for social media content from the store. */
	const socialCookies = useSelector( selectCookieSocial )

	/** Define a reference to the `jugendDebattiertButton`. */
	const jugendDebattiertDE = useRef( null )

	/** Define a state that holds the hostname. */
	const [ hostname, setHostname ] = useState( '' )
	/** Define a state to toggle the leaving site alert. */
	const [ toggle, handleToggle ] = useState( false )

	let YouTubeEmbeds = <SocialEmbedHint service="YouTube" />

	if ( socialCookies && allowedSocial[ 'YouTube' ] )

		YouTubeEmbeds = (
			<>
				<Box sx={{ aspectRatio: '16/9' }}>
					<iframe
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						frameBorder={ 0 }
						height="100%"
						src="https://www.youtube.com/embed/kaWoJRU9tfI"
						title="Saisonfinale Jugend debattiert"
						width="100%" />
				</Box>
				<Box sx={{ aspectRatio: '16/9' }}>
					<iframe
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						frameBorder={ 0 }
						height="100%"
						src="https://www.youtube.com/embed/C1jm2wN7HSA"
						title="Jugend debattiert für nichtgymnasiale Schulen"
						width="100%" />
				</Box>
				<Box sx={{ aspectRatio: '16/9' }}>
					<iframe
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						frameBorder={ 0 }
						height="100%"
						src="https://www.youtube.com/embed/K6KNXXkDkHY"
						title="Weil Kontroversen lohnen - Jugend debattiert"
						width="100%" />
				</Box>
			</>
		)

	return (
		<>
			<GatsbySeo title="Startseite" />
			<Grid container spacing={ 1 } sx={{ pt: 2 }}>
				<Grid item md={ 8 } xs={ 12 }>
					<Typography color="primary" variant="h3">Herzlich willkommen</Typography>
					<Typography color="secondary" gutterBottom variant="h6">auf den Internetseiten von Jugend debattiert in Nordrhein-Westfalen!</Typography>
					<Typography paragraph variant="body2">
						Informationen zum Projekt erhalten Sie auf <Link
						href="https://jugend-debattiert.de/"
						onClick={ event => {
							event.preventDefault()

							setHostname( jugendDebattiertDE.current.hostname )
							handleToggle( true )
						} }
						ref={ jugendDebattiertDE }
						target="_blank"
						underline="hover">jugend-debattiert.de <FontAwesomeIcon icon={ faExternalLinkAlt } size="sm" /></Link>
					</Typography>
					<Typography paragraph variant="body1">Noch keine Projektschule? Jugend debattiert lohnt sich – für Ihre Schülerinnen und Schüler und Sie!</Typography>
					<Typography paragraph variant="body2">Die Landeskoordinatoren wünschen Ihnen viel Spaß auf diesen Seiten und freuen sich über Ihr Feedback.</Typography>
					<Typography paragraph variant="body2">
						Jugend debattiert ist ein Wettbewerb für Schülerinnen und Schüler, der auf Initiative und unter Schirmherrschaft des Bundespräsidenten von der
						Gemeinnützigen Hertie-Stiftung, der Robert Bosch Stiftung, der Stiftung Mercator und der Heinz Nixdorf Stiftung in Kooperation mit den Kultusministerien, der Kultusministerkonferenz
						und den Parlamenten der Länder durchgeführt wird.
					</Typography>

					<LeavingSiteAlert
						handleToggle={ handleToggle }
						hostname={ hostname }
						reference={ jugendDebattiertDE }
						toggle={ toggle } />
				</Grid>
				<Grid item md={ 4 } xs={ 12 }>
					<Box sx={{
							backgroundColor: 'secondary.main',
							mb: 1,
							mt: 2,
							py: 10,
							[`& *`]: {
								color: 'common.white'
							},
					}}>
						<SlideShow />
					</Box>
					{ YouTubeEmbeds }
				</Grid>
			</Grid>
    	</>
	)
}

export default IndexPage
